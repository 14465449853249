/* Imporve Css Start Here */
.imporve-sec {
	padding: 70px 0;
}
.imporve-sec .heading-wrapper {
	text-align: center;
	padding-bottom: 20px;
	border-bottom: 1px solid #dadada;
	margin-bottom: 60px;
}
.imporve-sec .heading-wrapper h2 {
	font-size: 30px;
	line-height: 40px;
	color: var(--default-text-color);
	font-family: var(--font-light);
}
.imporve-sec .improve-box {
	text-align: center;
}
.imporve-sec .improve-box .icon-box img {
	width: 90px;
	height: 90px;
	margin: 0px auto;
	display: table;
}
.imporve-sec .improve-box .icon-box {
	padding-bottom: 20px;
}
.imporve-sec .improve-box .txt h4 {
	font-size: 24px;
	font-family: var(--font-light);
	line-height: 34px;
	min-height: 68px;
}
.imporve-sec .improve-box .txt p {
	font-family: var(--font-light);
	min-height: 78px;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}
.imporve-sec .improve-box .txt .button-group {
	margin-top: 30px;
}
/* Imporve Css End Here */

/* Case Study Css Start Here */
.case-study {
	padding: 30px 0 60px;
}
.case-study .case-study-box {
	background: #ededed;
}
.case-study .case-study-box .img-box img {
	width: 100%;
}
.case-study .case-study-box .content-wrapper {
	padding: 0px 40px 0 10px;
}
.case-study .case-study-box .content-wrapper h3 {
	font-size: 32px;
	line-height: 42px;
	color: var(--default-text-color);
	font-family: var(--font-default);
}
.case-study .case-study-box .content-wrapper p {
	color: var(--default-text-color);
}
.case-study .case-study-box .content-wrapper .button-group {
	margin-top: 25px;
}
.case-study .quoted {
	padding: 30px 24px 0 24px;
}
.case-study .quoted h3 {
	font-size: 22px;
	line-height: 38px;
	color: var(--extra-color-1);
	position: relative;
}
.case-study .quoted h3:before {
	content: "\f10d";
	font-family: "FontAwesome";
	opacity: 0.3;
	position: absolute;
	left: -22px;
	font-size: 16px;
	top: -10px;
}
.case-study .quoted h3:after {
	content: "\f10d";
	font-family: "FontAwesome";
	opacity: 0.3;
	font-size: 16px;
	margin-left: 10px;
}
.case-study .quoted h4 {
	font-size: 16px;
	line-height: 26px;
	color: #85bc20;
}
/* Case Study Css End Here */

/* Performace Sec Css Start Here */
.performace_sec {
	padding: 60px 0;
	background: #ededed;
}
.performace_sec .heading-wrapper {
	padding-bottom: 40px;
}
.performace_sec .heading-wrapper h2 {
	padding-bottom: 10px;
	font-size: 32px;
	font-family: var(--font-light);
	color: var(--default-text-color);
}
.sepsis-box {
	border: 1px solid #ededed;
	background: #fff;
	padding: 20px 25px 25px;
	height: 450px;
	position: relative;
}
.sepsis-box .title {
	font-size: 20px;
	color: var(--default-text-color);
	line-height: 25px;
	padding-bottom: 10px;
}
.sepsis-box p {
	font-size: 15px;
	color: var(--default-text-color);
	line-height: 23px;
}
.sepsis-box .button-group {
	margin-top: 40px;
	position: absolute;
	bottom: 30px;
	left: 25px;
}
/* Performance Sec Css End Here */
