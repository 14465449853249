/* Infection Css Start Here */
.query-sec {
	padding: 60px 0;
}
.query-sec .query-card {
	position: relative;
	border: 1px solid #ededed;
}
.query-sec .query-card .query-image {
	position: relative;
}
.query-sec .query-card .query-image img {
	height: 400px;
	width: 100%;
	object-fit: cover;
}
.query-sec .query-card .query-image .tag {
	position: absolute;
	background: #ffffffd1;
	color: #4e4e4f;
	padding: 5px 14px 5px;
	border-radius: 50px;
	top: 15px;
	left: 15px;
	text-transform: uppercase;
}
.query-sec .query-card .query-image .tag h6 {
	font-size: 11px;
	margin: 0;
}
.query-sec .query-card .query-image .tag i {
	margin-right: 3px;
}
.query-sec .query-card .content-wrapper h5 {
	display: flex;
	align-items: center;
	font-size: 10px;
	color: #000;
	font-family: var(--font-medium);
}
.query-sec .query-card .content-wrapper h5 p {
	margin: 0;
	font-size: 10px;
	color: #737373;
	margin-left: 8px;
}
.query-sec .query-card .content-wrapper h2 {
	color: #000;
	font-size: 32px;
	line-height: 42px;
	font-family: var(--font-default);
	padding-bottom: 10px;
}
.query-sec .query-card .content-wrapper p {
	color: #232323;
}
.query-sec .query-card .content-wrapper .button-group {
	margin-top: 30px;
}
.cta-cards-sec {
	padding: 0 0 60px;
}
.cta-cards {
	background: #fff;
	border: 1px solid #ededed;
	padding: 24px;
	transition: 0.6s;
	margin-top: 10px;
	height: 200px;
}
.cta-cards:hover {
	box-shadow: 0 4px 10px -3px rgb(0 0 0 / 30%);
}
.cta-cards .title {
	color: var(--extra-color-1);
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}
.cta-cards p {
	color: var(--default-text-color);
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}
.cta-cards .button-group {
	margin-top: 30px;
}
/* Infection Css End Here */
