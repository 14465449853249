/* Sentri Page Css Start Here */
.solution_sec {
	padding: 60px 0 100px;
}
.solution_sec .heading-wrapper {
	text-align: center;
	padding-bottom: 50px;
	border-bottom: 1px solid #dadada;
}
.solution_sec .heading-wrapper .title {
	font-size: 32px;
	line-height: 42px;
	font-family: var(--font-light);
	color: var(--default-text-color);
}
.solution_sec .solution-wrapper .heading-wrapper {
	text-align: left;
	padding: 45px 0 45px;
	border-bottom: 0;
}
.solution_sec .solution-wrapper .heading-wrapper h2.title {
	padding-bottom: 15px;
}
.solution_sec .solution-wrapper .heading-wrapper p {
	font-family: var(--font-light);
}
/* Sentri Page Css End Here */

/* Innovation Sec Css Start Here */
.innovation_sec {
	background: #ededed;
	padding: 60px 0 100px;
}
.innovation_sec .heading-wrapper {
	padding-bottom: 50px;
}
.innovation_sec .heading-wrapper h2 {
	font-family: var(--font-light);
	color: var(--default-text-color);
	font-size: 32px;
	line-height: 42px;
}
.innovation_sec .heading-wrapper p {
	font-family: var(--font-light);
}
.innovation-box {
	position: relative;
	text-align: center;
}
.innovation-box .icon-box img {
	width: 100px;
	height: 90px;
}
.innovation-box .content-box {
	padding-top: 20px;
}
.innovation-box .content-box h4 {
	font-size: 22px;
	line-height: 40px;
	padding-bottom: 10px;
}
.innovation-box .content-box p {
	font-family: var(--font-light);
	min-height: 100px;
}
/* Innovation Sec Css End Here */

/* Blog Sec Css Start Here */
.blog_sec {
	padding: 40px 0;
}
.blog_sec .heading-wrapper {
	padding-bottom: 50px;
}
.blog_sec .heading-wrapper h2 {
	font-family: var(--font-light);
	color: var(--default-text-color);
	font-size: 32px;
	line-height: 42px;
}
/* Blog Sec Css End Here */
