/* Support Css Start Here */
.support-sec {
	padding: 60px 0;
}
.support-sec .support-form {
	padding: 30px 25px;
	background: #ededed;
}
.support-sec .support-form .title {
	padding-bottom: 45px;
}
.support-sec .support-form .title h2 {
	font-size: 32px;
	line-height: 42px;
	font-family: "fira-light";
}
.support-sec .support-form .form-group {
	margin-bottom: 25px;
}
.support-sec .support-form .form-group label {
	color: #757575;
	font-size: 1rem;
	font-weight: 300;
	font-family: "fira-light";
}
.support-sec .support-form .form-group .form-control {
	color: #757575;
	border: 1px solid #bfbfbf;
	max-width: 600px;
	width: calc(100% - 40px);
	font-size: 1rem;
	font-weight: 300;
	height: 58px;
	line-height: 22px;
	border-radius: 0;
	padding: 15px 16px;
	box-shadow: none;
	outline: none;
}
/* Support Css End Here */
