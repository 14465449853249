/* All Variable Css Start Here */
@font-face {
	font-family: "fira-light";
	src: url("../fonts/FiraSans-Light.ttf");
}
@font-face {
	font-family: "fira-regular";
	src: url("../fonts/FiraSans-Regular.ttf");
}
@font-face {
	font-family: "fira-medium";
	src: url("../fonts/FiraSans-Medium.ttf");
}
@font-face {
	font-family: "fira-semibold";
	src: url("../fonts/FiraSans-SemiBold.ttf");
}
@font-face {
	font-family: "fira-bold";
	src: url("../fonts/FiraSans-Bold.ttf");
}

:root {
	/* Typography starts here */
	--font-default: "fira-regular";
	--font-heading: "fira-regular";
	--font-light: "fira-light";
	--font-medium: "fira-medium";
	--font-semibold: "fira-semibold";
	--font-bold: "fira-bold";
	/* Typography ends here */

	/* Colors starts here */
	--body-color: #fff;
	--extra-color-1: #007ac3;
	--extra-color-2: #000;
	--extra-color-3: #85bc20;
	--default-text-color: #232323;
	/* Colors ends here */

	/* button colors starts here */
	--primary-btn-color: #007ac3;
	--primary-btn-hover-back: #005b92;
	--primary-btn-text-color: #fff;
	--secondary-btn-color: #0000;
	--secondary-btn-text-color: #007ac3;
	--secondary-btn-hover-back: #007ac3;
	--secondary-btn-text-hover-color: #fff;
	/* button colors starts here */
}

/* All Variable Css End Here */
