/* About Us Css Start Here */
.motivational-note {
	padding: 80px 0;
	text-align: center;
}
.motivational-note h2 {
	padding-bottom: 15px;
	font-family: "fira-light";
	font-size: 35px;
	line-height: 45px;
}
.motivational-note p {
	font-size: 18px;
	line-height: 28px;
}
.about_volter {
	padding: 60px 0;
}
.about_volter .heading-wrapper {
	padding-bottom: 15px;
}
.about_volter .component-box {
	margin-bottom: 20px;
	background: #ededed;
}
.about_volter .component-box .img-box img {
	width: 100%;
}
.about_volter .component-box .content-wrapper h2 {
	font-size: 32px;
	line-height: 42px;
	font-family: var(--font-default);
}
.about_volter .component-box .content-wrapper .button-group {
	margin-top: 35px;
}
.about_volter .component-box .content-wrapper {
	padding: 0 35px;
}
.about_volter .heading-wrapper {
	padding-bottom: 15px;
}
#videoModal .modal-content {
	background: transparent;
	border: 0;
	padding: 0;
}
#videoModal .modal-header {
	border: 0;
	padding: 15px 0;
}
#videoModal .modal-content .modal-header button.btn-close {
	filter: brightness(0) invert(1);
	opacity: 1;
}
#videoModal .modal-content .modal-body {
	padding: 0;
}
#videoModal .modal-dialog {
	max-width: 65%;
}
.video-sec {
	padding: 20px 0 60px;
}
.video-sec .video-box {
	position: relative;
	padding: 70px 0 90px;
}
.video-sec .video-box button.btn {
	padding: 0;
	margin: 0;
	box-shadow: none;
	outline: none;
	background: transparent;
	position: relative;
	border: 0;
	border-radius: 0;
}
.video-sec .video-box button.btn .button-watch-video {
	position: absolute;
	bottom: 0;
	min-width: auto;
	background-color: rgba(0, 0, 0, 0.5);
	font-size: 14px;
	line-height: 16px;
	padding: 20px;
	color: #fff;
	font-size: 16px;
}
.video-sec .video-box button.btn .button-watch-video i {
	margin-right: 10px;
}
.video-sec .video-box button.btn:hover .button-watch-video {
	background: #007ac3;
	color: #fff;
}
.video-sec .content-wrapper {
	padding: 0 80px;
}
.video-sec .content-wrapper h3 {
	padding-bottom: 10px;
	font-size: 32px;
	line-height: 42px;
	color: #353535;
}
.video-sec .content-wrapper p {
	line-height: 28px;
	color: #000000;
}
.video-sec .row-bg {
	background: #f6f6f6;
	width: 75%;
	position: absolute;
	right: 0;
	z-index: -1;
	height: 100%;
	margin: 0px auto 0px 0px;
}
.video-sec .video-box button.btn {
	padding: 0;
	margin: 0;
	box-shadow: none;
	outline: none;
	background: transparent;
	position: relative;
	border: 0;
	border-radius: 0;
}
.video-sec .video-box button.btn .button-watch-video {
	position: absolute;
	bottom: 0;
	min-width: auto;
	background-color: rgba(0, 0, 0, 0.5);
	font-size: 14px;
	line-height: 16px;
	padding: 20px;
	color: #fff;
	font-size: 16px;
}
.video-sec .video-box button.btn .button-watch-video i {
	margin-right: 10px;
}
.video-sec .video-box button.btn:hover .button-watch-video {
	background: #007ac3;
	color: #fff;
}
.video-sec .content-wrapper {
	padding: 0 80px;
}
.video-sec .content-wrapper h3 {
	padding-bottom: 10px;
	font-size: 32px;
	line-height: 42px;
	color: #353535;
}
.video-sec .content-wrapper p {
	line-height: 28px;
	color: #000000;
}
.diversity_sec {
	padding: 40px 0 80px;
}
.diversity-box .img-box img {
	height: 328px;
	width: 100%;
	object-fit: cover;
	object-position: center center;
}
.diversity-box .content-wrapper {
	background: #fff;
	padding: 24px;
	position: relative;
	width: 78%;
	margin: -90px 0px 0px auto;
}
.diversity-box .content-wrapper .title {
	color: #353535;
	font-family: "fira-regular";
	margin-bottom: 10px;
}
.diversity-box .content-wrapper .button-group {
	margin-top: 30px;
}
/* About Us Css End Here */
