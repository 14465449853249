/* Auth Css Start Here */
.with-background {
	background: #ededed;
}
.note_sec {
	padding: 60px 0;
	text-align: center;
}
.note_sec h2 {
	padding-bottom: 15px;
	font-family: "fira-light";
	font-size: 32px;
	line-height: 42px;
}
.note_sec h4 {
	font-size: 24px;
	color: #232323;
	line-height: 40px;
	font-family: "fira-light";
}
.sole_sec {
	padding: 10px 0 60px;
}
.sole_sec .sole-box {
	background: #ededed;
}
.sole_sec .sole-box .content-wrapper {
	padding: 0 35px 0 20px;
}
.sole_sec .sole-box .content-wrapper .button-group {
	margin-top: 40px;
}
.sole_sec .sole-box .content-wrapper h3 {
	margin-bottom: 15px;
	font-size: 32px;
	font-family: "fira-regular";
	line-height: 42px;
}
.other-login {
	padding: 20px 0 120px;
}
/* Auth Css End Here */
